import axios from 'axios';
import store from '@/store';
import * as API from '@/services/API';

export const authClient = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    withCredentials: true
});

export default {
    login (payload) {
        return authClient.post('/auth/login', payload).then((response) => {
            store.commit('LOGIN', response.data);
            API.apiClient.defaults.headers.Authorization = 'Bearer ' + response.data;
        });
    },
    register (payload) {
        return authClient.post('/auth/register', payload).then((response) => {
            store.commit('LOGIN', response.data);
            API.apiClient.defaults.headers.Authorization = 'Bearer ' + response.data;
        });
    },
    oauth (provider, code) {
        return authClient.get(`/auth/${provider}/callback?code=${code}`).then((response) => {
            store.commit('LOGIN', response.data);
            API.apiClient.defaults.headers.Authorization = 'Bearer ' + response.data;
        });
    },
    apple (payload) {
        return authClient.post('/auth/apple', payload).then((response) => {
            store.commit('LOGIN', response.data);
            API.apiClient.defaults.headers.Authorization = 'Bearer ' + response.data;
        });
    }
};
