import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import Echo from 'laravel-echo';

// Bulma
import Buefy from 'buefy';

// Validation
import Vuelidate from 'vuelidate';

// Drag Scroll
import VueDragscroll from 'vue-dragscroll';

// Vue Swiper
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/swiper-bundle.css';

// Moment
import VueMoment from 'vue-moment';
import moment from 'moment-timezone';

// Google Maps
import * as VueGoogleMaps from 'vue2-google-maps';

// Flags
import CountryFlag from 'vue-country-flag';

// Importing the global css file
import '@/assets/scss/_variables.scss';
import '@/assets/scss/_global.scss';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheck, faCheckCircle, faInfoCircle, faExclamationTriangle, faCalendarDay, faExclamationCircle, faArrowUp, faAngleRight, faAngleLeft, faAngleDown, faEye, faEyeSlash, faCaretDown, faCaretUp, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
library.add(faCheck, faCheckCircle, faInfoCircle, faCalendarDay, faExclamationTriangle, faExclamationCircle, faArrowUp, faAngleRight, faAngleLeft, faAngleDown, faEye, faEyeSlash, faCaretDown, faCaretUp, faUpload);
Vue.component('vue-fontawesome', FontAwesomeIcon);
Vue.component('country-flag', CountryFlag);

Vue.use(Buefy, { defaultIconComponent: 'vue-fontawesome', defaultIconPack: 'fas' });
Vue.use(VueAwesomeSwiper);
Vue.use(Vuelidate);
Vue.use(VueDragscroll);
Vue.use(VueMoment, {
    moment
});
Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyBFQHyWNfhhxGQGQoX40pcH2E0ZHBXXoI0'
    }
});

Vue.config.productionTip = false;

/**
 * Pusher Channels
 */
window.Pusher = require('pusher-js');
window.Echo = new Echo({
    authEndpoint: `${process.env.VUE_APP_API_URL}/broadcasting/auth`,
    auth: {
        headers: {
            Authorization: `Bearer ${store.state.token}`
        }
    },
    encrypted: true,
    broadcaster: 'pusher',
    key: process.env.VUE_APP_PUSHER_CHANNELS_KEY,
    cluster: 'eu',
    forceTLS: true
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
