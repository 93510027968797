<template>
    <div id="app">
        <router-view/>
    </div>
</template>
<script>
export default {
    // here or main.js?
    mounted () {
        if (this.$store.state.token && this.$store.state.uid) {
            window.Echo.private(`user.${this.$store.state.uid}`)
                .listen('UserNotification', () => {
                    this.$store.state.notifications = true;
                });
        }
    }
};
</script>
<style lang="scss">
$speed-slow: 150ms !default;
$speed-slower: 250ms !default;
@import "~bulma";
@import "~buefy/src/scss/components/_switch.scss";
@import "~buefy/src/scss/components/_dropdown.scss";
@import "~buefy/src/scss/components/_datepicker.scss";
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap);
body {
    margin: 0;
}
.switch input[type=checkbox]:checked + .check.is-success {
    background: #23C86A!important;
}

#app {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    width: 100%;
    max-width: 600px;
    margin: auto;
    overflow: hidden;
}
.admin-width {
    #app {
        max-width: none;
    }
}
#nav {
    padding: 30px;
    a {
        font-weight: bold;
        color: #2c3e50;
        &.router-link-exact-active {
            color: #42b983;
        }
    }
}
</style>
