<template>
	<div class="home container container-full-height min">
		<span class="overlay" :class="{active: showModal || showLoginModal}"></span>
		<span class="gradient"></span>
		<div class="content-container">
			<div class="welcome-text">
				<img class="mission-logo" alt="The Mission to Seafarers" src="../assets/tmts.png" />
				<img class="happy-logo" alt="The Mission to Seafarers" src="../assets/happy-at-sea-logo.png" />
				<p>Free Port Welfare and Wellbeing Services from The Mission to Seafarers</p>
			</div><!-- welcome text -->
			<div class="welcome-funnels">
				<img class="seafarers-img" alt="The Mission to Seafarers" src="../assets/seafarers.png" />
				<div class="button-wrap">
					<Button routeName="/create-profile" icon="RegisterIcon" class="btn-green btn-icon btn-icon-right btn-register" text="Create a profile"></Button>
					<Button @click.native="modalActive" icon="LoginIcon" class="btn-icon btn-icon-right btn-login" text="I already have a profile"></Button>
					<router-link :to="{ name: 'Dashboard' }" class="text-link text-link-blue">Use the app without a profile &raquo;</router-link>
				</div>
			</div><!-- welcome funnels -->
		</div><!-- content container -->
		<div ref="modalLogin" class="phone-modal" :style="{ bottom: modalLogin + 'px'}" :class="{active: showModal}">
			<span class="modal-line"></span>
			<div class="modal-title-bar">
				<div class="icon-wrap">
					<LoginIcon style="width: 28px; height: 28px;" class="icon login-icon" />
					<span>Login</span>
				</div><!-- icon wrap -->
				<GreyCloseIcon @click="modalActive" style="width: 30px; height: 30px;" class="icon close" />
			</div><!-- modal title bar -->
            <Button icon="AppleIcon" @click.native="apple" class="btn-med btn-icon btn-icon-left btn-apple" text="Sign in with Apple"></Button>
			<Button @click.native="loginModalActive" icon="EmailIcon" class="btn-grey btn-med btn-icon btn-icon-left btn-email" text="Sign in with Email"></Button>
			<a @click="modalActive" class="text-link text-link-grey">I don't have a profile &raquo;</a>
		</div><!-- modal -->
		<div ref="modalEmail" class="phone-modal modal-email" :style="{ bottom: modalLoginHeight + 'px'}" :class="{active: showLoginModal}">
			<span class="modal-line"></span>
			<div class="modal-title-bar">
				<div class="icon-wrap">
					<EmailIcon style="width: 31px; height: 31px;" class="icon email-icon" />
					<span>Login with my Email Address</span>
				</div><!-- icon wrap -->
				<GreyCloseIcon @click="loginModalActive" style="width: 31px; height: 31px;" class="icon close" />
			</div><!-- modal title bar -->
			<form style="padding-top: 10px;" class="form" @submit.prevent>
				<div class="field field-icon" :class="{ 'field-error': $v.form.email.$error || errorStyling == true }">
					<label for="email-input" class="label">Email Address</label>
					<div class="control">
						<input id="email-input" class="input" type="email" placeholder="Enter your email address" v-model="form.email" enterkeyhint="next" inputmode="email" @keyup.enter="$refs.password_input.focus()" @keydown.enter.prevent>
						<EmailIcon style="width: 21px; height: 22px;" class="icon icon-email" />
					</div>
				</div>
				<div class="field field-gap field-icon" :class="{ 'field-error':$v.form.password.$error || errorStyling == true }">
					<label for="password-input" class="label">Password</label>
					<div class="control">
						<input id="password-input" ref="password_input" class="input" type="password" placeholder="Enter your password" v-model="form.password" enterkeyhint="done" @keyup.enter="loginUser">
						<KeyIcon style="width: 20px; height: 21px;" class="icon icon-key" />
					</div>
					<router-link :to="{ name: 'Home' }" class="field-sub field-sub-red">Forgotten your password?</router-link>
				</div>
				<div class="field error" v-if="errorMessage">
					<span class="field-error-message">Incorrect email address or password.</span>
				</div>
				<Button icon="ArrowRightIcon" class="btn-med btn-green btn-icon btn-icon-right btn-arrow" text="Login with my Email Address" @click.native="loginUser"></Button>
			</form>
			<a @click="modalActive" class="text-link text-link-grey">I don't want to login with my email address &raquo;</a>
		</div><!-- modal -->
	</div>
</template>
<script>
import { required, email } from 'vuelidate/lib/validators';
import LoginIcon from '@/assets/login.svg';
import GreyCloseIcon from '@/assets/close-grey.svg';
import EmailIcon from '@/assets/email.svg';
import KeyIcon from '@/assets/key.svg';
import Button from '@/components/Button.vue';
// import WelcomeText from '@/components/WelcomeText.vue';
// import BaseInput from '@/components/BaseInput.vue';
import AuthService from '@/services/AuthService';
import UserService from '@/services/UserService';
import { Keyboard, KeyboardResize } from '@capacitor/keyboard';
import { Capacitor } from '@capacitor/core';
import { SignInWithApple } from '@capacitor-community/apple-sign-in';

export default {
	name: 'Home',
	components: {
    	Button,
    	// WelcomeText,
    	// BaseInput,
    	GreyCloseIcon,
    	KeyIcon,
    	LoginIcon,
    	EmailIcon
	},
	data () {
		return {
			modalLogin: null,
			modalLoginHeight: null,
			showModal: false,
			showLoginModal: false,
			form: {
				email: '',
				password: ''
			},
			errorMessage: null,
			blankPassword: null,
			errorStyling: null
		};
	},
	validations: {
		form: {
			email: {
				required,
				email
			},
			password: {
				required
			}
		}
	},
	computed: {
		matchHeightModal () {
			return '-' + this.$refs.modalLogin.clientHeight;
		},
		matchHeightModalEmail () {
			return '-' + this.$refs.modalEmail.clientHeight;
		}
	},
	methods: {
		modalActive () {
	    	this.showModal = !this.showModal;
	    	this.showLoginModal = false;
	    },
	    loginModalActive () {
	    	this.modalLoginHeight = '-' + this.$refs.modalEmail.clientHeight;
	    	this.showLoginModal = !this.showLoginModal;
	    	this.showModal = false;
	    },
	    async loginUser () {
			this.errorMessage = false;
			this.errorStyling = false;
			const data = {
            	email: this.form.email,
            	password: this.form.password
            };
			this.$v.form.$touch();
			if (this.$v.form.$error) {
				if (this.$v.form.email.$error) {
				} else {
					this.errorMessage = true;
				}
			} else {
				// this.LOGIN_USER(data).then(() => {
				// 	this.$router.push({ name: 'dashboard' });
				// 	Socket.send(JSON.stringify({
				// 		token: localStorage.getItem('token')
				// 	}));
				// }).catch(() => {
				// 	this.errorMessage = true;
				// 	this.errorStyling = true;
				// })
                try {
                    await AuthService.login(data).then(() => {
                        this.$router.push({ name: 'Dashboard' });
                    });
                } catch (error) {
                    this.errorMessage = true;
                    this.errorStyling = true;
                }
			}
	    },
        oauth (provider) {
            return `${process.env.VUE_APP_API_URL}/auth/${provider}`;
        },
        async oauthCallback (provider, code) {
            await AuthService.oauth(provider, code).then(() => {
                this.$router.push({ name: 'Dashboard' });
            });
        },
        async apple () {
            let token;
            let name;
            await SignInWithApple.authorize({
                clientId: 'org.missiontoseafarers.app',
                redirectURI: process.env.VUE_APP_URL,
                scopes: 'email name'
            }).then((response) => {
                token = response.response.identityToken;
                name = response.response.givenName + ' ' + response.response.familyName;
            }).catch((error) => {
                console.log(error);
            });
            if (token) {
                AuthService.apple({
                    token: token,
                    name: name
                }).then(() => {
                    this.$router.push({ name: 'Dashboard' });
                }).catch((error) => {
                    console.log(error);
                });
            }
        }
	},
	mounted () {
        if (this.$route.query.provider && this.$route.query.code) {
            this.oauthCallback(this.$route.query.provider, this.$route.query.code);
        }
		if (this.$route.params.showLoginModal) {
			setTimeout(() => {
            	this.showLoginModal = true;
         	}, 100);
		}
		this.modalLogin = this.matchHeightModal;
		this.modalLoginHeight = this.matchHeightModalEmail;
        if (this.$store.getters.isAuthenticated) {
            UserService.getUser().then((response) => {
                if (response.status === 200) {
                    this.$router.push({ name: 'Dashboard' });
                }
            });
        }
        if (Capacitor.platform !== 'web') {
            Keyboard.setAccessoryBarVisible({
                isVisible: true
            });
            Keyboard.setResizeMode({
                mode: KeyboardResize.None
            });
            Keyboard.addListener('keyboardWillShow', (info) => {
                this.$refs.modalEmail.style.marginBottom = `${info.keyboardHeight}px`;
            });
            Keyboard.addListener('keyboardWillHide', () => {
                this.$refs.modalEmail.style.marginBottom = '0';
            });
        }
	}
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_global.scss";
.gradient {
	position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 30%;
    background: rgb(255,255,255);
	background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(0,145,255,1) 100%);
}
.welcome-text {
	position: relative;
	max-width: 320px;
	margin: 0 auto;
	padding-top: 40px;
	width: 100%;
	span.sub {
		font-size: 17px;
		font-weight: 600;
		color: #0A203D;
		display: block;
		margin-bottom: 18px;
	}
	.mission-logo {
		width: 100px;
    	display: block;
    	margin: 0 auto 35px;
	}
	.happy-logo {
		width: 200px;
		margin-bottom: 25px
	}
	h1 {
		margin: 0 0 10px;
		color: $white;
		font-size: 26px;
		line-height: 31px;
	}
	p {
		margin: 0;
		color: #0A203D;
		font-weight: 400;
		font-size: 17px;
	}
}
.content-wrap {
	position: relative;
	border-radius: 35px 35px 0 0;
	padding: 40px 28px 28px 28px;
	background: $white;
	width: 100%;
	transition: 0.5s ease;
	z-index: 100;
	flex: auto;
}
.container {
	position: relative;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}
.container-full-height {
	height: 100vh;
	&.min {
		min-height: 100vh;
		height: auto;
	}
}
.content-container {
	display: flex;
	flex: auto;
    flex-direction: column;
    justify-content: space-between;
    padding: 28px;
}
.welcome-funnels {
	position: relative;
	margin-top: 50px;
	.seafarers-img {
		position: relative;
	    width: 180px;
	    height: auto;
	    top: 30px;
	    z-index: 0;
	}
	.button-wrap {
		z-index: 5;
		position: relative;
	}
	.text {
		margin-bottom: 30px;
	}
	p {
		color: $white;
		margin: 0;
		strong {
			color: $white;
		}
	}
}
</style>
